import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';

// Partials
import Quantities from '@/components/partials/Quantities';

import { format, isSameDay, differenceInDays, getFormattedDateTime } from '@/misc/datetime';
import { calculateLastTimeToUnbook } from '@/misc/common';
import { BookingType, ServiceType } from '@/types';
import { Trans } from '@lingui/macro';


interface Props {
    finishedBooking?: BookingType;
    booking: BookingType;
    service?: ServiceType;
}

export function Summary(props: Props) {
    const service = props.finishedBooking
        ? props.finishedBooking.Service
        : (props.service as ServiceType);
    const booking = props.finishedBooking || props.booking;
    // @ts-ignore
    const time = props.finishedBooking ? props.finishedBooking : props.booking.time;

    const dateTimeFormat = 'd MMM, p';
    const timeFormat = 'p';
    const formattedTime = isSameDay(time.From, time.To)
        ? `${getFormattedDateTime(time.From)}-${format(time.To, timeFormat)}`
        : `${getFormattedDateTime(time.From)} - ${format(time.To, dateTimeFormat)}`;

    const lastTimeToUnbook = props.finishedBooking
        ? props.finishedBooking.LastTimeToUnBook
        : calculateLastTimeToUnbook(service, time);

    const isCancellable = differenceInDays(time.From, lastTimeToUnbook) <= 365;

    const addToQueue = !!(time.Free === 0 && service.EnableBookingQueue);
    // const fullyBooked = time.Free === 0 && !addToQueue;

    return (
        <div>
            <Table size="sm" responsive borderless>
                <tbody>
                    {booking.Id ? (
                        <tr>
                            <td>
                                <strong>
                                    <Trans id="bookingId"></Trans>
                                </strong>
                            </td>
                            <td>{booking.Id}</td>
                        </tr>
                    ) : null}
                    <tr>
                        <td>
                            <strong>
                                <Trans id="service"></Trans>
                            </strong>
                        </td>
                        <td>{service.Name}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                <Trans id="time"></Trans>
                            </strong>
                        </td>
                        <td>{formattedTime}</td>
                    </tr>
                    {!addToQueue ? <tr>
                        <td>
                            <strong>
                                <Trans id="cancelBefore"></Trans>
                            </strong>
                        </td>
                        <td>
                            <span>
                                {isCancellable ? (
                                    getFormattedDateTime(lastTimeToUnbook)
                                ) : (
                                    <Trans id="notCancellable"></Trans>
                                )}
                            </span>
                        </td>
                    </tr> : null}
                    {booking.StatusId === 5 && (
                        <>
                            <tr>
                                <td>
                                    <strong>
                                        <Trans id="status"></Trans>
                                    </strong>
                                </td>
                                <td>
                                    <span className="text-danger">
                                        <Trans id="notPaid"></Trans>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        <Trans id="bookingMustBePaidBefore"></Trans>
                                    </strong>
                                </td>
                                <td> {getFormattedDateTime(booking.PaymentExpiration)}</td>
                            </tr>
                        </>
                    )}
                    {booking.BookedResourceTypes ?
                        booking.BookedResourceTypes.map((resourceType) =>
                                <tr key={resourceType.Id}>
                                    <td>
                                        <strong>{resourceType.Name}</strong>
                                    </td>
                                    <td>
                                        {resourceType.Resources.map(
                                            (resource) => resource.Name
                                        ).join(',')}
                                    </td>
                                </tr>
                        ) : null}
                    {service.ResourceTypes &&
                        service.ResourceTypes.map((resourceType) =>
                            {
                                // @ts-ignore
                                const isResourceSelected = Object.keys(booking.resources).length > 0;
                                return resourceType.SelectableByUser ? (
                                    <tr key={resourceType.Id}>
                                        <td>
                                            <strong>{resourceType.Name}</strong>
                                        </td>
                                        <td>
                                            {/* @ts-ignore */}
                                            {isResourceSelected ? resourceType.Resources.filter(resource => Object.values(booking.resources).includes(resource.Id) ).map(
                                                (resource) => resource.Name
                                            ).join(',') : null}
    
                                            {/* @ts-ignore */}
                                            {!isResourceSelected ? (<em style={{ color: 'var(--bokamera-grey-600)'}}><Trans id="Summary.resourceAutomaticallySelected" /></em>) : null}
                                        </td>
                                    </tr>
                                ) : null
                            }
                        )}
                    {booking.CustomFieldValues ?
                        booking.CustomFieldValues
                        .filter(customFieldValue => customFieldValue.Value)
                        .map((customFieldValue, index) => (
                            <tr key={index}>
                                <td>
                                    <strong>{customFieldValue.Name}</strong>
                                </td>
                                <td>{customFieldValue.Value}</td>
                            </tr>
                        )): null}
                </tbody>
            </Table>
            <Quantities />
        </div>
    );
}

const mapStateToProps = ({ booking }: any) => ({ booking });

export default connect(mapStateToProps)(Summary);
