import React, { useMemo } from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import { format, toDate, isSameDay, isBefore } from '@/misc/datetime';
import TimeSlot from '@/components/elements/TimeSlot';
import Spinner from '@/components/elements/Spinner';

import {
    Configuration,
    TimesStateType,
    ServiceType,
    TimeType,
    CompanyType,
    ResourceInterface,
    ResourceTypeType,
} from '@/types';

import './Times.css';
import { Trans } from '@lingui/macro';
import { chain } from 'lodash';
import { ApplicationState } from '@/store';

interface Props extends OwnProps {
    configuration: Configuration;
    times: TimesStateType;
    service: ServiceType;
    dispatch: Dispatch;
    company: CompanyType;
    resource: ResourceInterface;
    resourceType: ResourceTypeType;
}

interface OwnProps {
    service: ServiceType;
    resourceType?: ResourceTypeType;
    resource?: ResourceInterface;
    showDate?: boolean;
}

export function Times(props: Props) {
    const { configuration, company, service, resource, resourceType, showDate = true, dispatch } = props;

    const times = props.times[props.service.Id];

    let EnableShowBookedTimes = false;
    if(company?.BookingSettings?.EnableShowBookedTimes) {
        EnableShowBookedTimes = true
    }
    
    const hasMultipleResource =
        service.MultipleResource.Active &&
        service.MultipleResource.Min !== service.MultipleResource.Max;

    const sameDateResourcePredicate = (time: TimeType) => {
        const sameDay =
            configuration.selectedDate &&
            isSameDay(toDate(time.From), configuration.selectedDate as Date);
        const sameResource = !resource || resource.Id === time.ResourceId;

        return sameDay && sameResource && (EnableShowBookedTimes || time.Free > 0);
    }

    const validTime = (time: TimeType) => isBefore(time.From, time.To);

    const filteredTimes = useMemo(() => {
        return hasMultipleResource
            ? chain(times.times).groupBy("From")
                .reduce((acc, curr) => {
                const _time = curr.reduce((acc, curr) => {
                    acc = {
                        ...acc,
                        Free: acc.Free + curr.Free
                    }
                    return acc;
                })
                // @ts-ignore
                acc.push(_time);
            
                return acc;
                }, [])
                .value()
                .filter(validTime)
                .filter(sameDateResourcePredicate)
            : times.times
            .filter(validTime)
            .filter(sameDateResourcePredicate);
    }, [times, EnableShowBookedTimes, configuration.selectedDate]);

    console.log('2', filteredTimes)

    if (!configuration.selectedDate)
        return (
            <Alert color="info">
                <Trans id="selectDate"></Trans>
            </Alert>
        );

    const dateString = format(toDate(configuration.selectedDate), 'd MMM');

    if (times.isLoading)
        return (
            <Spinner>
                <Trans id="loadingTimes"></Trans>
            </Spinner>
        );

    if (filteredTimes.length === 0) {
        return (
            <span className="text-danger">
                <Trans id="noSpotsFoundFor"></Trans> {dateString}
            </span>
        );
    }

    return (
      <div style={{ marginTop: "10px", display: "flex", flexWrap: "wrap", gap: '6px' }}>
        {showDate ? <h5>{dateString}</h5> : null}
        {filteredTimes.map((time: TimeType, index: number) => {
          const addToQueue = !!(time.Free === 0 && service.EnableBookingQueue);
          const fullyBooked = time.Free === 0 && !addToQueue;

          return (
            <TimeSlot
              key={index}
              showFreeTimesLeft={!!company?.BookingSettings?.ShowFreeTimesLeft}
              bookedTimeSlotText={configuration.bookedTimeSlotText}
              showEndTimeOnTimeslots={configuration.showEndTimeOnTimeslots}
              time={time}
              onClick={() => {
                if (!fullyBooked) {
                  dispatch({
                    type: "SELECT_TIME",
                    time: time,
                    service: service,
                    resourceType: resourceType,
                    resource: resource,
                  });

                  // because we use this component in time and resource flow
                  if(resource) {
                      dispatch({
                        type: "SELECT_RESOURCE",
                        payload: {
                            service: service,
                            resourceType: resourceType,
                            resource: resource,
                        }
                      });
                  }
                }
              }}
              multipleBooking={
                service.GroupBooking.Active || service.MultipleResource.Active
              }
              className="Times__TimeSlot"
              single={times.single}
              multiple={times.multiple}
              addToQueue={addToQueue}
              fullyBooked={fullyBooked}
            />
          );
        })}
      </div>
    );
}

const mapStateToProps = ({ configuration, company, times }: ApplicationState) => ({
    configuration: configuration.data,
    company: company.data,
    times,
});

export default compose<React.ComponentType<OwnProps>>(connect(mapStateToProps))(Times);
